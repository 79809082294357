<template>
	<div>
		<div style="text-align: center; font-size:18px;padding: 10px;">比邻微印打印机共享教程</div>
		
		<div style="padding: 20px;">
			第一步：下载比邻微印打印端程序到电脑，解压缩后，双击【比邻微印打印端.exe】启动客户端。
			
			<div>
				下载链接：<a href="http://blwy.mmteck.cn/images/blwy.zip" style="color: royalblue;">http://blwy.mmteck.cn/images/blwy.zip</a>
			</div>
			<img  :src="'./images/1.png'" style="width: 100%;"/>
		</div>
		<div style="padding: 20px;">
			第二步：将注册码填入客户端，点击确定即可自动读取所有打印机
			<img  :src="'./images/2.png'" style="width: 100%;"/>
		</div>
		
		<div style="padding: 20px;">
			第三步：在【公众号菜单-我的】下载打印码，张贴至店内即可
			<img  :src="'./images/3.png'" style="width: 100%;"/>
		</div>
		
		<div style="padding: 20px;padding-bottom: 100px;">
			第四步：在【公众号菜单-我的】页面进行提现
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		mounted() {

			
		},
		methods: {
			
			

		}
	}
</script>

<style scoped>
	
</style>
